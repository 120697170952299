@import './variables.scss';

.project-header {
    padding: 100px 0 25px;
    text-align: center;
}

.projects.container {
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 100px;
    max-width: 1200px;

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.project {
    max-width: 575px;
    height: 800px;
    margin: 20px 10px;
    padding: 20px 10px;
    box-shadow: $boxShadow;
    border: 1px solid $accentLight;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .image-container {
        width: 100%;
        height: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 2;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    span {
        font-size: $small;
    }

    p {
        font-size: $small;
        padding: 5px 0;
        font-style: italic;
    }

    .info-container {
        width: 95%;
        margin: 0 auto;
        order: 3;
    }

    .title-container {
        order: 1;
        margin: 0 auto;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        height: 500px;
        max-width: 95%;
        width: 95%;
        margin: 10px auto;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .image-container {
            order: 1;
            width: 49%;
            height: 100%;
        }

        .title-container {
            order: 2;
            width: 49%;
        }

        .info-container {
            order: 2;
            width: 49%;
        }

        .stack-container {
            margin-top: 40px;
        }
    }

    @media (max-width: $mobileL) {
        height: fit-content;
    }
}

.stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 5px;

    .tag {
        background: $accentLight;
        font-size: $small;
        padding: 5px 7px;
        border-radius: 5px;
        margin: 5px;
    }
}

.roles {
    width: 90%;
    margin: 0 auto;
    list-style: disc;
    list-style-position: outside;

    li {
        font-size: $small;
        text-align: left;
        margin: 1rem 0;
    }
}

.clickable {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.5s ease;
    &:hover {
        transform: scale(1.05);
        transition: transform 0.5s ease;
    }
}
