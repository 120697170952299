$accentColor: #ffa364;
$accentDark: #e5813d;
$accentLight: #ffd2b3;
$darkGray: #444c55;
$lightGray: #9da3aa;
$white: #fafafa;

$tiny: 1.2rem;
$small: 1.6rem;
$medium: 2rem;
$large: 2.4rem;

$mobileS: 321px;
$mobileM: 376px;
$mobileL: 426px;
$tablet: 769px;
$laptop: 1090px;
$laptopL: 1441px;
$desktop: 2561px;

$boxShadow: rgba(0, 0, 0, 0.12) 2px 3px 10px 0px;

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animate($name, $duration, $iteration, $direction) {
    -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
    -o-animation-duration: $duration;
    animation-duration: $duration;
    -webkit-animation-iteration-count: $iteration;
    -moz-animation-iteration-count: $iteration;
    -o-animation-iteration-count: $iteration;
    animation-iteration-count: $iteration;
    -webkit-animation-name: $name;
    -moz-animation-name: $name;
    -o-animation-name: $name;
    animation-name: $name;
    -webkit-animation-direction: $direction;
    -moz-animation-direction: $direction;
    -o-animation-direction: $direction;
    animation-direction: $direction;
}

@include keyframes(slide-in) {
    from {
        opacity: 0;
        top: 100%;
    }
    to {
        opacity: 1;
        top: 88%;
    }
}

@mixin slide-in {
    @include animate(slide-in, 1.5s, 1, normal);
}
