@import './variables.scss';

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    width: 199%;
    background-color: rgba(0, 0, 0, 0.45);
}

.modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
}

.modal-content {
    background-color: $white;
    position: relative;
    top: 200px;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-height: 500px){
        top: 100px;
    }
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 7px;

    button {
        background: $white;
        border: none;
        cursor: pointer;
    }
    .close-button {
        color: $lightGray;
        font-size: $large;
    }

    @media (max-width: 500px) {
        .close-button {
            font-size: 2rem;
        }
    }
}

.modal-body {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0px 35px;

    .contact-col {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        align-items: center;
        min-width: 150px;

        .ant-icon {
            color: $accentColor;
            font-size: 8rem;
        }

        span {
            color: $lightGray;
            font-size: $small;
        }
    }

    .divider {
        width: 3px;
        background-color: $lightGray;
    }

    @media (max-width: 500px) {
        .contact-col {
            min-width: 120px;
            .ant-icon {
                font-size: 6rem;
            }

            span {
                font-size: 1.4rem;
            }
        }
    }

    @media (max-width: $mobileM) {
        .contact-col {
            min-width: 90px;
            .ant-icon {
                font-size: 5rem;
            }

            span {
                font-size: 1.2rem;
            }
        }
    }
}
