@import './variables.scss';

.intro.container {
    justify-content: space-between;
    height: 100vh;
    min-height: 100%;

    .image-container {
        width: 35%;

        img {
            border-radius: 50%;
            object-fit: contain;
            width: 100%;
            height: auto;
        }
    }

    .bio-container {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        h1,
        p,
        .quote,
        h3 {
            color: $darkGray;
        }

        p,
        .quote {
            font-size: $small;
        }

        p {
            margin: 35px 0;
            font-style: italic;
        }

        .hr-container {
            width: 90%;
            margin: 0 auto;
        }
        hr {
            overflow: visible; /* For IE */
            padding: 0;
            border: none;
            border-top: medium double $accentLight;
            color: $accentLight;
            text-align: center;
        }
        hr:after {
            content: '§';
            display: inline-block;
            position: relative;
            top: -1.12rem;
            font-size: $small;
            padding: 0 0.4rem;
            background: white;
        }
    }

    @media (max-width: $laptop) {
        width: 90%;

        h1 {
            font-size: 7rem;
        }

        h3 {
            font-size: 2.6rem;
        }

        .bio-container {
            width: 65%;
        }

        .image-container {
            width: 30%;
        }
    }

    @media (max-width: $tablet) {
        min-height: 815px;
        flex-direction: column-reverse;
        justify-content: center;
       
        .image-container {
            width: 50%;
        }

        .bio-container {
            width: 90%;

            h1 {
                font-size: 6rem;
            }

            h3 {
                font-size: 2.2rem;
            }

            p,
            .quote {
                font-size: $small;
            }

            p{
                margin: 15px 0;
            }
        }
    }

    @media (max-width: 500px) {
        min-height: 720px;
        .bio-container {
            h1 {
                font-size: 5rem;
            }
            h3 {
                font-size: 2rem;
            }
        }
    }

    @media (max-width: $mobileL) {
        min-height: 665px;
        .bio-container {
            h1 {
                font-size: 4rem;
            }
        }
    }

    @media (max-width: 280px) {
        min-height: 600px;
        .bio-container {
            h1 {
                font-size: 3rem;
            }
            h3 {
                font-size: 1.7rem;
            }
            p,
            .quote {
                font-size: $tiny;
            }
        }
    }
}


