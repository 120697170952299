@import './variables.scss';

.nav {
    background: $darkGray;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.nav-links {
    height: 60px;

    a, button {
        color: $white;
        font-size: $medium;
        text-decoration: none;
        background: $darkGray;
        border: none;

        &:hover{
            text-decoration: underline;
            color: $accentLight;
        }
    }

    @media (max-width: $mobileM) {
        li:first-child {
            display: none;
        }
    }
}
