@import './variables.scss';

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: $white;
    color: $darkGray;
    font-family: 'Share', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    cursor: pointer;
    color: inherit;
    transition: background-color 0.2s ease-in-out;
}

a:hover {
    transition: background-color 0.2s ease-in-out;
}

h1 {
    font-size: 8rem;
}

h2 {
    font-size: 4.6rem;
}

h3 {
    font-size: 3rem;
}

.container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    align-items: center;
    
}
